import {createStore} from 'zustand'
import {persist} from 'zustand/middleware'
import {immer} from 'zustand/middleware/immer'

export type PlaygroundAuthType =
  | 'Anonymous'
  | 'InheritAuthFromParent'
  | 'DataroomScope'

type DataroomScopeAuthorizationParams = {
  dataroomId: string
  userId: string
}

export type AuthorizationParams = {
  currentAuthType: PlaygroundAuthType
} & Partial<DataroomScopeAuthorizationParams>

export type PlaygroundState = {
  authorizationParams: AuthorizationParams
}
export type PlaygroundActions = {
  setAuthType: (authType: PlaygroundAuthType) => void
  setDataroomId: (dataroomId: string) => void
  setUserId: (userId: string) => void
}

export type PlaygroundStore = PlaygroundState & PlaygroundActions

export const playgroundStore = createStore<PlaygroundStore>()(
  persist(
    immer(set => ({
      authorizationParams: {
        currentAuthType: 'InheritAuthFromParent',
      },
      setAuthType: (authType: PlaygroundAuthType) =>
        set(state => {
          state.authorizationParams.currentAuthType = authType
        }),
      setDataroomId: dataroomId =>
        set(state => {
          state.authorizationParams.dataroomId = dataroomId
        }),
      setUserId: userId =>
        set(state => {
          state.authorizationParams.userId = userId
        }),
    })),
    {
      name: 'playground:authorization',
    },
  ),
)

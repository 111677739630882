import type {GetTokenSilentlyOptions} from '@auth0/auth0-react'
import {useAuth0} from '@auth0/auth0-react'
import {explorerPlugin} from '@graphiql/plugin-explorer'
import '@graphiql/plugin-explorer/dist/style.css'
import {createGraphiQLFetcher} from '@graphiql/toolkit'
import Box from '@mui/material/Box'
import cls from 'classnames'
import {GraphiQL} from 'graphiql'
import 'graphiql/graphiql.css'
import {isEqual} from 'lodash'
import React, {useEffect, useMemo, useRef, useState} from 'react'

import graphqlSchema from '@ansarada/graphql-schema/schema.graphql'

import {parseSchema} from '~/lib/apollo/schema-helper'
import type {AuthorizationParams} from '~/lib/stores/playground'
import {playgroundStore} from '~/lib/stores/playground'

import {PlaygroundPortal} from './playground.portal'

const graphiqlClasses = {
  editor: 'graphiql-editor',
  unstyled: 'graphiql-un-styled',
  hidden: 'hidden',
  active: 'active',
}

const schema = parseSchema(graphqlSchema)

const createAuthorizationHeader = async (
  selectedParams: AuthorizationParams,
  previousParams: AuthorizationParams,
  getAccessTokenSilently: (
    options?: GetTokenSilentlyOptions,
  ) => Promise<string>,
): Promise<{Authorization?: string}> => {
  if (selectedParams.currentAuthType === 'Anonymous') {
    return {}
  }

  const shouldIgnoreCache = !isEqual(previousParams, selectedParams)
  const tokenOptions: GetTokenSilentlyOptions = {
    ignoreCache: shouldIgnoreCache,
    ...(selectedParams.currentAuthType === 'DataroomScope' && {
      dataRoomId: selectedParams.dataroomId,
      dataRoomUserId: selectedParams.userId,
    }),
  }

  const accessToken = await getAccessTokenSilently(tokenOptions)
  return {Authorization: `Bearer ${accessToken}`}
}

export function Playground() {
  const [isMounted, setIsMounted] = useState(false)
  const {getAccessTokenSilently} = useAuth0()
  const previousAuthorizationParamsRef = useRef<AuthorizationParams>({
    currentAuthType: 'InheritAuthFromParent',
  })

  useEffect(() => {
    if (isMounted) return

    const editorToolsTabBar = document.getElementsByClassName(
      'graphiql-editor-tools',
    )[0] as any as HTMLDivElement
    const editorToolsSection = document.getElementsByClassName(
      'graphiql-editor-tool',
    )[0] as any as HTMLDivElement

    if (
      editorToolsTabBar &&
      editorToolsSection &&
      !document.getElementById('authorization-tab')
    ) {
      const authorizationButton = document.createElement('button')
      authorizationButton.id = 'authorization-tab'
      authorizationButton.className = 'graphiql-un-styled'
      authorizationButton.textContent = 'Authorization'

      const authorizationSection = document.createElement('div')
      authorizationSection.id = 'authorization-section'
      authorizationSection.className = 'graphiql-editor hidden'

      const tabs = [
        editorToolsTabBar.childNodes[0],
        editorToolsTabBar.childNodes[1],
      ]
      const sections = Array.from(editorToolsSection.childNodes)

      tabs.forEach((e, index) =>
        e.addEventListener('click', () => {
          const currentTab = e as HTMLButtonElement
          const activeSession = sections[index] as HTMLDivElement
          currentTab.className = cls(
            graphiqlClasses.unstyled,
            graphiqlClasses.active,
          )
          activeSession.className = graphiqlClasses.editor
          authorizationSection.className = cls(
            graphiqlClasses.editor,
            graphiqlClasses.hidden,
          )
        }),
      )

      authorizationButton.onclick = e => {
        const variablesTab = tabs[0] as HTMLButtonElement
        const headersTab = tabs[1] as HTMLButtonElement
        const variablesSection = sections[0] as HTMLDivElement
        const headersSection = sections[1] as HTMLDivElement

        variablesTab.className = graphiqlClasses.unstyled
        variablesSection.className = cls(
          graphiqlClasses.editor,
          graphiqlClasses.hidden,
        )
        headersTab.className = graphiqlClasses.unstyled
        headersSection.className = cls(
          graphiqlClasses.editor,
          graphiqlClasses.hidden,
        )
        authorizationSection.className = graphiqlClasses.editor

        authorizationButton.className = cls(
          graphiqlClasses.unstyled,
          graphiqlClasses.active,
        )
      }

      editorToolsTabBar.addEventListener('click', e => {
        if (!(e.target as HTMLElement)?.closest(`#${authorizationButton.id}`)) {
          authorizationButton.className = graphiqlClasses.unstyled
        }
      })

      editorToolsTabBar.insertBefore(
        authorizationButton,
        editorToolsTabBar.childNodes[2],
      )
      editorToolsSection.appendChild(authorizationSection)
    }

    setIsMounted(true)
  }, [isMounted])

  const fetcher = useMemo(() => {
    return createGraphiQLFetcher({
      url: `${process.env.ONE_GATEWAY_BASE_URL}/graphql`,
      fetch: async (url, init) => {
        const {current: previousAuthorizationParams} =
          previousAuthorizationParamsRef
        const selectedAuthorizationParams =
          playgroundStore.getState().authorizationParams

        const headers = {
          ...init?.headers,
          ...(await createAuthorizationHeader(
            selectedAuthorizationParams,
            previousAuthorizationParams,
            getAccessTokenSilently,
          )),
        }

        const response = await fetch(url, {
          ...init,
          headers,
        })

        // Update previousSelectAuthType
        previousAuthorizationParamsRef.current = selectedAuthorizationParams
        return response
      },
    })
  }, [getAccessTokenSilently])

  const defaultQuery = `
    query MeQuery {
      me {
        email
        firstName
        lastName
      }
    }
  `
  const defaultHeaders = `{
  "X-Wg-Trace":true
}`

  return (
    <Box data-test-id="playground-editor" height="100%">
      <GraphiQL
        fetcher={fetcher}
        defaultQuery={defaultQuery}
        defaultHeaders={defaultHeaders}
        shouldPersistHeaders
        plugins={[
          explorerPlugin({
            showAttribution: false,
          }),
        ]}
        schema={schema}
      >
        <GraphiQL.Logo>Ansarada</GraphiQL.Logo>
      </GraphiQL>
      {isMounted && <PlaygroundPortal />}
    </Box>
  )
}

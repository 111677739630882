import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {createPortal} from 'react-dom'
import {useStore} from 'zustand'

import {Menu} from '@ansarada/components/Menu'
import {TextField} from '@ansarada/components/TextField'

import type {PlaygroundAuthType} from '~/lib/stores/playground'
import {playgroundStore} from '~/lib/stores/playground'

const authorizationOptions: Array<{
  label: string
  value: PlaygroundAuthType
}> = [
  {label: 'Inherit auth from parent', value: 'InheritAuthFromParent'},
  {label: 'Dataroom scope', value: 'DataroomScope'},
  {label: 'Anonymous', value: 'Anonymous'},
]

const DataroomScopeContent = () => {
  const {dataroomId, userId} = useStore(
    playgroundStore,
    state => state.authorizationParams,
  )
  const handleChangeDataroomId = useStore(
    playgroundStore,
    state => state.setDataroomId,
  )
  const handleChangeUserId = useStore(playgroundStore, state => state.setUserId)

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" gap={4} alignItems="center">
        <Typography flexShrink={0} width="7rem" fontWeight={400}>
          Dataroom ID
        </Typography>
        :
        <TextField
          sx={{flexGrow: 1}}
          value={dataroomId || ''}
          onChange={e => handleChangeDataroomId(e.target.value)}
          inputProps={{
            'data-test-id': 'dataroom-id-field',
          }}
        />
      </Box>
      <Box display="flex" gap={4} alignItems="center">
        <Typography flexShrink={0} width="7rem" fontWeight={400}>
          User ID
        </Typography>
        :
        <TextField
          sx={{flexGrow: 1}}
          value={userId || ''}
          onChange={e => handleChangeUserId(e.target.value)}
          inputProps={{
            'data-test-id': 'user-id-field',
          }}
        />
      </Box>
    </Box>
  )
}

const AuthorizationSectionContentLayout = ({
  children,
}: React.PropsWithChildren) => {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="body2" fontSize={14}>
        {children}
      </Typography>
    </Box>
  )
}

const AuthorizationSection = () => {
  const selectedAuthType = useStore(
    playgroundStore,
    state => state.authorizationParams.currentAuthType,
  )
  const handleSelectAuthType = useStore(
    playgroundStore,
    state => state.setAuthType,
  )

  const AuthorizationSectionContent = {
    DataroomScope: <DataroomScopeContent />,
    InheritAuthFromParent: (
      <AuthorizationSectionContentLayout>
        This request is using access token from current login user.
      </AuthorizationSectionContentLayout>
    ),
    Anonymous: (
      <AuthorizationSectionContentLayout>
        This request is served as an anonymous request without an authorization
        header.
      </AuthorizationSectionContentLayout>
    ),
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
      }}
    >
      <Box
        sx={{
          flexBasis: 300,
          flexShrink: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        <Box>
          <Typography marginBottom={1}>Auth Type</Typography>
          <TextField
            select
            value={selectedAuthType}
            onChange={e =>
              handleSelectAuthType(e.target.value as PlaygroundAuthType)
            }
            data-test-id="auth-type-field"
          >
            {authorizationOptions.map(({label, value}) => (
              <Menu.Item key={value} value={value}>
                {label}
              </Menu.Item>
            ))}
          </TextField>
        </Box>

        <Typography variant="body2" fontSize={14}>
          The authorization header will be automatically generated when you send
          the request.
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: 'hsl(var(--color-neutral))',
          width: '1px',
          height: '100%',
          marginX: 5,
          flexShrink: 0,
        }}
      />
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        {AuthorizationSectionContent[selectedAuthType]}
      </Box>
    </Box>
  )
}

export const PlaygroundPortal = () => {
  const authorizationSection = document.getElementById('authorization-section')

  if (!authorizationSection) {
    return null
  }

  return <>{createPortal(<AuthorizationSection />, authorizationSection)}</>
}

import { MenuList, MenuListProps } from '@mui/material';
import { PropsWithChildren } from 'react';

import { UtilityProps } from '../../../utils/prop';
import { GroupHeader } from '../internal/GroupHeader';

type BaseProps = {
  /** Title of the group */
  title?: string | React.ReactNode;
} & UtilityProps;

type Props = BaseProps & Omit<MenuListProps, keyof BaseProps>;

/** This component inherits [MUI MenuList's API](https://mui.com/material-ui/api/menu-list/)\
 * See the [API documented on Storybook](https://ansarada-design-system.vercel.app/?path=/docs/elements-menu-props--documentation)
 * ---
 * @reimplementable true
 */
const Group = ({ children, title, ...props }: PropsWithChildren<Props>) => {
  return (
    <MenuList {...props}>
      {title && <GroupHeader>{title}</GroupHeader>}
      {children}
    </MenuList>
  );
};

export { Group };
export default Group;
